body {
  margin: 0;
  font-family: "Fira Sans", Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #999 !important;
  background: #fff7ea !important;
}

h3 {
  color: #222;
  font-size: 16px;
}

p {
  color: #999;
  font-size: 14px;
  line-height: 22px;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: #b9b5ff;
}

a:hover,
i:hover {
  opacity: .7 !important;
  text-decoration: none !important;
}

.holder {
  width: 65%;
  text-align: center;
  background: #fff;
  border: 2px solid #d8f6f5;
  padding: 60px 0;
  margin: auto;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: "Fira sans";
}


/* Filters */

.filters-list {
  padding: 10px 0;
  margin: 0;
}

.filters-list li {
  display: inline-block;
  list-style-type: none;
  margin-right: 30px;
}

.filters-list div {
  color: #666;
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
}

.filters-list span {
  color: #666;
  font-weight: normal;
  cursor: pointer;
}

.filters-list div:hover {
  opacity: .7;
}

.activated {
  color: #ff9900 !important;
}


/* Payments status */

.finished-status {
  background-color: #7EB88E;
}

.approved-status {
  background-color: #6D9DE7;
}

.pending-status {
  background-color: #E5BA49;
}

.canceled-status {
  background-color: #E35858;
}

.important-status {
  background-color: #b9b5ff;
}

/* Tabs */

.MuiTypography-body1 {
  font-weight: bold !important;
  font-family: "Fira Sans" !important;
}


/* Modal form */

.rowStyles {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
}

.columnStyles {
  display: flex;
  flex-direction: column;
}


/* Responsive */

@media only screen and (max-width: 960px) {

  .loginBg {
    background: none !important;
  }

  .loginContainer {
    width: 100% !important;
  }

  .container,
  .holder {
    width: 94% !important;
  }


  /* Aside (Tabs) */

  .MuiListItemIcon-root {
    margin-right: 0 !important;
  }


  /* Subheader */

  .subHeader {
    display: block !important;
    justify-content: none !important;
  }

  /* Filters */

  .filters-list {
    width: 100%;
    padding: 0;
    margin: 0 0 25px 0;
  }
  
  .filters-list li {
    display: block;
    margin-bottom: 2px;
    margin-right: 0;
  }

  .filters-list li:first-child {
    border-top: none;
  }

  .filters-list li > div {
    display: block;
    border: 1px solid #D2EDEC;
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
    padding: 0 15px;
    border-radius: 4px;
    margin-bottom: 5px;
  }

  .activated {
    border-color: #b9b5ff !important;
  }


  /* Actions */

  .userLi {
    display: block !important;
    justify-content: none !important;
  }

  .liInfos {
    width: 100%;
    display: block;
    float: none;
  }

  .liActions {
    width: 100%;
    display: block;
    margin-top: 10px;
  }

  .liActionsLink {
    margin-left: 10px !important;
  }


  /* Modal form */

  .rowStyles {
    display: block !important;
    justify-content: flex-start !important;
    padding: 0;
    padding-top: 30px;
  }

  .columnStyles {
    display: block !important;
    flex-direction: none !important;
  }

  .column02 {
    margin-top: 20px;
  }
}


